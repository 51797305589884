import axios from "axios";
import { Buffer } from "buffer";
axios.defaults.baseURL = "https://api-access.accumedintel.com";
// axios.defaults.baseURL = "http://localhost:3030";

export default async (method, route, data) => {
    const metrics = {
        userAgent: window.navigator.userAgent,
        method: method,
        url: route,
        params: method == "get" ? data : undefined,
        data: method != "get" ? data : undefined,
        created_at: Math.round(new Date() / 1000),
    };

    const token = document.cookie
        ?.split("; ")
        ?.find((row) => row.startsWith("access_token"))
        ?.split("=")[1];

    try {
        const res = await axios({
            method,
            url: route,
            responseType: "json",
            params: method == "get" ? data : undefined,
            data: method != "get" ? data : undefined,
            headers: {
                Authorization: token ? "Bearer " + token : undefined,
                "X-Metrics": Buffer.from(JSON.stringify(metrics)).toString("base64"),
            },
        });

        return res.data;
    } catch (error) {
        return { success: false, status_code: error?.response?.status };
    }
};
