import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { ContextGlobal } from "../contexts/ContextGlobal";
import { FormPackageSearch } from "./Forms/FormPackageSearch";
import { EulaModal } from "./Modals/EulaModal";
import { MyReports } from "./MyReports/MyReports";
import { useMediaPredicate } from "react-media-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import { StandardMCP } from "./Home/StandardMCP";
import { ProMCP } from "./Home/ProMCP";
import { FrontPageScrollButton } from "./Home/FrontPageScrollButton";
import useScroll from "./CustomHooks/useScroll";
import { FutureAnalysisProducts } from "./Home/FutureAnalysisProducts";
import { HistoricalAnalysisProducts } from "./Home/HistoricalAnalysisProducts";
import { ProductSheetCard } from "./Home/ProductSheetCard";
import { StandardMCPNew } from "./Home/StandardMCPNew";
import { ProMCPNew } from "./Home/ProMCPNew";
import { DownloadSampleModal } from "./Modals/DownloadSampleModal";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Frontpage = (props) => {
    const query = useQuery();

    const { setActivePage, session, createRequest, clearState, sendConfirmEmail, setPromoCode, clearExcelData } = useContext(ContextGlobal);

    const phoneSize = useMediaPredicate("(max-width: 450px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");
    const laptopSize = useMediaPredicate("(max-width: 1200px)");

    useEffect(() => {
        setActivePage("createReport");
        clearState("LEAVE_CONTACTS");
        // eslint-disable-next-line
    }, []);

    const history = useNavigate();

    const [loading, setLoading] = useState(false);
    const [billAnalysisLoading, setBillAnalysisLoading] = useState(false);
    const [histLoading, setHistLoading] = useState(false);
    const [disLoading, setDisLoading] = useState(false);

    const [product, setProduct] = useState("");

    const [notification, setNotification] = useState(null);
    const [confirmed, setConfirmed] = useState(false);
    const [notifLoading, setNotifLoading] = useState(false);

    const [executeScrollToBottom, scrollRefToBottom] = useScroll();
    const [executeScrollToTop, scrollRefTop] = useScroll();

    const [displayScrolltoTop, setDisplayScrollToTop] = useState(false);

    // notifications will have to come from somewhere...
    useEffect(() => {
        if (Object.keys(session).length > 0) {
            if (!session.isConfirmed && session.isGuest == 0) {
                setConfirmed(false);
                setNotification("Please confirm your email by clicking here");
            } else {
                setConfirmed(true);
            }
        }
    }, [session]);

    // only for confirming email right now, will have to change to handle all types of notifications...
    const handleNotification = async () => {
        if (!confirmed) {
            setNotifLoading(true);
            await sendConfirmEmail({
                email: session.email,
                type: "email_confirmation",
            });
            setNotifLoading(false);
            setNotification("Please check your email for a confirmation link");
        }
    };

    useEffect(() => {
        if (query.get("referral_token")) {
            const promo = query.get("referral_token");
            setPromoCode(promo);
        }
    }, []);

    const showBackToTopRef = useRef(null);

    const getOffset = () => {
        if (showBackToTopRef.current != null) {
            const rect = showBackToTopRef.current.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return rect.top + scrollTop - 400;
        }
    };

    const listenToScroll = () => {
        let heightToHideFrom = getOffset();
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll < heightToHideFrom) {
            setDisplayScrollToTop && // to limit setting state only the first time
                setDisplayScrollToTop(false);
        } else {
            setDisplayScrollToTop(true);
        }
    };
    // display "Back to top"
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    // const loginEulaBtn = useRef(null);

    // useEffect(() => {
    //     if (session.userID) {
    //         if (!session.consentToEula) {
    //             loginEulaBtn.current.click();
    //         }
    //     }
    // }, [session]);

    return (
        <>
            {/* Sign EULA on login... */}
            {/* <button 
                className="btn access-primary-button"
                data-bs-toggle="modal"
                data-bs-target="#login-eula"
                ref={loginEulaBtn}
                style={{display: "none"}}
            ></button> */}
            {notification !== null && (
                <div className="row mb-3">
                    <div className="col-lg-1 d-none d-md-block"></div>
                    <div className="col-lg-10 col-md-6 col-xs-12">
                        <div
                            className="card card-product"
                            style={{
                                width: "100%",
                                borderRadius: "2em",
                                background: "#ce0044", // needs to be dynamic - #ce0044 for bad #71e099 for good
                            }}
                        >
                            <div className="card-banner">
                                <div className="d-flex justify-content-between">
                                    <div
                                        className="notification"
                                        style={{
                                            cursor: "pointer", // will have to change depending on the kind of notif
                                        }}
                                        onClick={(e) => {
                                            // e.stopPropagation();
                                            handleNotification();
                                        }}
                                    >
                                        {notifLoading ? <Oval color="white" height={20} width={20} /> : <>{notification}</>}
                                    </div>
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setNotification(null);
                                        }}
                                    >
                                        <FontAwesomeIcon className="notification-close" icon={faTimes} color="#fff" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 d-none d-md-block"></div>
                </div>
            )}
            {/* When MCPs are down use this banner */}
            {/* <div className="row mb-3">
                <div className="col-lg-1 d-none d-md-block"></div>
                <div className="col-lg-10 col-md-6 col-xs-12">
                    <div
                        className="card card-product"
                        style={{
                            width: "100%",
                            borderRadius: "2em",
                            background: "#fef7c2", // needs to be dynamic - #ce0044 for bad #71e099 for good
                        }}
                    >
                        <div className="card-banner">
                            <div className="d-flex justify-content-between">
                                <div
                                    className="notification"
                                    style={{color: "black",}}
                                >
                                    We apologize for the inconvenience, our Standard Medical Cost Projections are currently unavailable. You can choose to order a Pro Medical Cost Projection and we'll charge you for a Standard or you can reach out to <a href="mailto:info@accumedintel.com" style={{ textDecoration: "none", color: "#0acaf2" }}>info@accumedintel.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1 d-none d-md-block"></div>
            </div> */}
            {/** mcp section */}
            <div className="row mb-5" ref={scrollRefTop}>
                <div className="col-xxl-2 col-xl-1 d-none d-xl-block">
                    <FrontPageScrollButton message="See ALL Products" location="top" color="#000046" executeScroll={executeScrollToBottom} />
                </div>
                <div className="col-xxl-8 col-xl-10 col-12" style={{}}>
                    {/** standard mcp */}
                    <StandardMCPNew setProduct={setProduct} />
                </div>
                <div className="col-xxl-2 col-xl-1 d-none d-xl-block"></div>
            </div>
            <div className="row">
                {/** pro mcp */}
                <div className="col-xxl-2 col-xl-1 d-none d-xl-block"></div>
                <div className="col-xxl-8 col-xl-10 col-12">
                    <ProMCPNew setProduct={setProduct} />
                </div>
                <div className="col-xxl-2 col-xl-1 d-none d-xl-block"></div>
            </div>
            {/** othre products section */}
            <div className="row my-5" id="other-products-section" ref={scrollRefToBottom}>
                <div className="col-md-1" ref={showBackToTopRef}>
                    {!laptopSize ? (
                        <>
                            {displayScrolltoTop ? (
                                <div className="bottom-scroll-to-section">
                                    <FrontPageScrollButton message="Back to Top" location="bottom" color="#212529" executeScroll={executeScrollToTop} />
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </div>
                <div className={"col-md-5 " + (phoneSize ? "mb-5" : "")}>
                    <HistoricalAnalysisProducts />
                </div>
                <div className="col-md-5">
                    <div className="row">
                        {/* future analysis products */}
                        <div className="col-md-12">
                            <FutureAnalysisProducts />
                        </div>
                        {/* price sheet  */}
                        <div className={"col-md-12 " + (phoneSize ? "my-5" : "mt-5")}>
                            <ProductSheetCard />
                        </div>
                    </div>
                </div>
            </div>
            <DownloadSampleModal product={product} />
            {/* {session.email && (
                <div style={{ minHeight: "100vh", paddingTop: 25 }} id="my-reports">
                    <MyReports />
                </div>
            )} */}
            {/* <EulaModal /> */}
        </>
    );
};
