import React, { useContext, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router";
import { ContextGlobal } from "../../contexts/ContextGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

export const FormPackageSearch = (props) => {
    const { session, searchPackages } = useContext(ContextGlobal);
    const history = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const location = useLocation();

    const onSubmit = (data) => {
        if (data.terms === undefined) {
            setSearched(true);
            // history("/builder/package_search");
        } else {
            if (data.terms.length === 0) {
                setSearched(true);
                history("/builder/package_search");
            } else {
                setSearched(true);
                searchPackages(data.terms);
                // history("/builder/package_search?terms=" + data.terms);
                history({
                    pathname: "/builder/package_search",
                    search: "?terms=" + data.terms,
                });
            }
        }
    };

    const [searched, setSearched] = useState(props?.session?.email !== undefined ? true : false);

    const searchRef = useRef();

    useEffect(() => {
        if (!props.empty) {
            setSearched(true);
        }
    }, [props.empty]);

    useEffect(() => {
        if (props.empty && session.email === undefined) {
            ReactTooltip.show(searchRef.current);
        }
        // eslint-disable-next-line
    }, [props.empty]);

    const closeSearchTooltip = () => {
        ReactTooltip.hide(searchRef.current);
        setSearched(true);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"input-group"} style={{ marginBottom: "8px" }}>
                <input
                    type="text"
                    // disabled
                    className={"package-search-form-input form-control " + (errors.terms ? "is-invalid" : "")}
                    placeholder="Search medical procedures"
                    ref={searchRef}
                    defaultValue={(location.search) ? decodeURI(location.search?.toString().split("=")[1]) : ""}
                    {...register("terms", {
                        required: props.frontpage ? false : true,
                    })}
                    data-tip
                    data-for="package-search-tooltip"
                    // data-event="mouseenter"
                    // data-event-off="mouseleave"
                />
                <button /*disabled*/ className="btn btn-outline-secondary package-search-button" type="submit">
                    <FontAwesomeIcon icon={faSearch} />
                </button>
            </div>
            {!searched && !props.frontpage && (
                <ReactTooltip id="package-search-tooltip" effect="solid" multiline={true} delayHide={500} delayUpdate={500} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                    <span
                        style={{
                            position: "absolute",
                            right: "5px",
                            top: "1px",
                        }}
                    >
                        <FontAwesomeIcon
                            onClick={(e) => closeSearchTooltip()}
                            style={{
                                cursor: "pointer",
                            }}
                            icon={faTimes}
                            color="#B7B7CB"
                        />
                    </span>
                    <span>Let's start adding procedures to your report</span>
                </ReactTooltip>
            )}
        </form>
    );
};
