import { faChevronDown, faEllipsisV, faInfo, faTimes, faPlus, faCircle, faQuestionCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link, useLocation } from "react-router-dom";
import { ContextGlobal } from "../../../contexts/ContextGlobal";
import { FormPatientName } from "../../Forms/FormPatientName";
import { FormEula } from "../../Forms/FormEula";
import { FormFrequencyDuration } from "../../Forms/FormFrequencyDuration";
import { FormPackageSearch } from "../../Forms/FormPackageSearch";
import ReactTooltip from "react-tooltip";
import { useMediaPredicate } from "react-media-hook";

import { SOSModal } from "../../Modals/SOSModal.js";
import ReactPaginate from "react-paginate";
import { Oval } from "react-loader-spinner";
import { Package } from "../Components/Package.js";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const BuilderPackageSearch = (props) => {
    const { packages, requestPackages, searchPackages, addRequestPackages, updateRequestPackage, reorderReportPakages, deleteReportPackage, session, setActivePage, packageLoading, searchLoading } = useContext(ContextGlobal);

    const handleDragEnd = (result) => {
        setDragDropTooltip(true);

        if (result.destination) {
            reorderReportPakages(result.source.index, result.destination.index);
        }
    };

    const collapseFreqRef = useRef([]);
    const collapseBillsRef = useRef([]);

    const billsTooltipRef = useRef();

    const location = useLocation();

    const query = useQuery();

    const [selected, setSelected] = useState();
    const [packageID, setPackageID] = useState();
    const [passPackageID, setPassPackageID] = useState(null); // extra package ID for eula form
    const [addToLoad, setAddToLoad] = useState(null);

    useEffect(() => {
        if (location.hash !== "") {
            const reqpackID = location.hash.split("#requestPackage");
            setSelected(parseInt(reqpackID[1], 10));

            if (collapseFreqRef.current.length !== 0) {
                collapseFreqRef.current[reqpackID[1]].classList.toggle("show");
            }
            if (collapseBillsRef.current.length !== 0) {
                collapseBillsRef.current[reqpackID[1]].classList.toggle("show");
            }
        }
    }, [location]);

    useEffect(() => {
        // only search based on query when packages are empty
        if (packages.length === 0) {
            searchPackages(query.get("terms"));
        }
        setActivePage("");
        // eslint-disable-next-line
    }, []);

    // Display the Tooltip automatically when adding the first package
    useEffect(() => {
        if (requestPackages.length !== 0 && requestPackages.length < 2 && session.email === undefined) {
            ReactTooltip.show(billsTooltipRef.current);
        }
    }, [requestPackages, session]);

    const [added, setAdded] = useState(session.email !== undefined ? true : false);

    const [dragDropTooltip, setDragDropTooltip] = useState(session.email !== undefined ? true : false);
    const [occurrenceOpen, setOccurrenceOpen] = useState(session.email !== undefined ? true : false);
    const [includeTooltip, setIncludeTootlip] = useState(session.email !== undefined ? true : false);
    const [billsTooltip, setBillsTooltip] = useState(session.email !== undefined ? true : false);

    const phoneSize = useMediaPredicate("(max-width: 447px)");
    const midSizeScreen = useMediaPredicate("(max-width: 767px)");

    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [selectedPage, setSelectedPage] = useState(null);

    const handlePageClick = (data) => {
        setSelectedPage(data.selected);
        if (midSizeScreen) {
            setOffset(Math.ceil(data.selected * 4));
        } else {
            setOffset(Math.ceil(data.selected * 10));
        }
    };

    useEffect(() => {
        if (packages.length > 0) {
            if (midSizeScreen) {
                setPageCount(Math.ceil(packages.length / 4));
            } else {
                setPageCount(Math.ceil(packages.length / 10));
            }
        } else {
            setPageCount(0);
        }
    }, [packages, midSizeScreen]);

    const [occurrenceExpand, setOccurrenceExpand] = useState();
    const [billsExpand, setBillsExpand] = useState();

    const closePackageTooltip = () => {
        ReactTooltip.hide(billsTooltipRef.current);
        setBillsTooltip(true);
    };

    const closeOccurrenceTooltip = () => {
        setOccurrenceOpen(true);
    };

    const closeBillTooltip = () => {
        setIncludeTootlip(true);
    };

    const removeBill = (requestPackageID, bill) => {
        updateRequestPackage({ requestPackageID: requestPackageID, bill: bill, include: false });
    };

    const includeBill = (requestPackageID, bill) => {
        updateRequestPackage({ requestPackageID: requestPackageID, bill: bill, include: true });
    };

    const [checkIcon, setCheckIcon] = useState(false);
    const [buttonColor, setButtonColor] = useState(null);

    const handleAddToReport = async (packageID, eula) => {
        setPackageID(null);
        setPassPackageID(null);
        if (eula) {
            // setButtonColor(null); if we want the navy blue button
            setAddToLoad(packageID);
            setPackageID(packageID);
            const res = await addRequestPackages(packageID);
            setAdded(true);
            if (res) {
                // set the icon to checkmark
                setCheckIcon(true);
                // set background color to teal
                setButtonColor("teal");
                // wait x seconds
                setTimeout(() => {
                    setCheckIcon(false);
                    // set background color to navy
                    // setButtonColor("navy"); if we want the navy blue button
                    setButtonColor(null);
                    // set text to "Add another"
                }, 1500); // 1.5 seconds
            }
            setAddToLoad(false);
        } else {
            setAddToLoad(packageID);
            setPackageID(packageID);
            setPassPackageID(packageID);
        }
    };

    return (
        <>
            <div className="row g-0 access-breadcrumb">
                <Link to="/">&lt; Product Selection</Link>
            </div>
            <div className="card content-card mb-5">
                <div className="row g-0">
                    <div className="col-md-6 col-12" style={{ borderRight: midSizeScreen ? "" : "1px solid #e1e5ec", paddingRight: midSizeScreen ? "" : "40px" }}>
                        <h3 className="">Get an Standard Medical Cost Projection Report</h3>
                        <span className="" style={{ fontSize: "11pt", fontWeight: "500", color: "#1db8ea" }}>
                            COST: $500
                        </span>
                        <p style={{ fontSize: "11pt" }}>Fast, easy, inexpensive</p>
                        <p style={{ fontSize: "11pt" }}>You pick the treatment you want to see. Search our system of over 800 medical services. This is the fastest turnaround at the best price.</p>
                        <ul style={{ fontSize: "11pt", paddingLeft: "20px" }}>
                            <li>Inlcudes pre-op, post-op, and all relevant provider services</li>
                            <li>Fast and easy 4-step submission</li>
                            <li>1-2 day turnaround</li>
                        </ul>
                        <h3 className="mb-4">Select Treatments</h3>
                        {/* When MCPs are down use this banner */}
                        {/* <div className="row mb-3">
                            <div className="col-lg-1 d-none d-md-block"></div>
                            <div className="col-lg-10 col-md-6 col-xs-12">
                                <div
                                    className="card card-product"
                                    style={{
                                        width: "100%",
                                        borderRadius: "2em",
                                        background: "#fef7c2", // needs to be dynamic - #ce0044 for bad #71e099 for good
                                    }}
                                >
                                    <div className="card-banner">
                                        <div className="d-flex justify-content-between">
                                            <div
                                                className="notification"
                                                style={{color: "black",}}
                                            >
                                                We apologize for the inconvenience, our Standard Medical Cost Projections are currently unavailable. If you needed to add additional procedures to this report please submit as is and email <a href="mailto:info@accumedintel.com" style={{ textDecoration: "none", color: "#0acaf2" }}>info@accumedintel.com</a> so that we can assist you. Thank you for your understanding.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 d-none d-md-block"></div>
                        </div> */}
                        <FormPackageSearch session={session} builder={true} empty={packages.length === 0} query={query} />
                        {packages.length === 0 ? <p style={{ fontSize: "0.8em", marginBottom: "6px", color: "#787878" }}>No Results</p> : <p style={{ fontSize: "0.8em", marginBottom: "6px", color: "#787878" }}>Found {packages.length} package(s)</p>}
                        {packages.length > 10 && <ReactPaginate previousLabel={"previous"} nextLabel={"next"} breakLabel={"..."} breakClassName={"break-me"} pageCount={pageCount} marginPagesDisplayed={2} pageRangeDisplayed={5} onPageChange={handlePageClick} containerClassName={"pagination"} activeClassName={"active"} forcePage={selectedPage} />}
                        <div className="d-flex flex-row" style={{ marginBottom: "32px" }}>
                            <span className="faq-contact-link" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#sos">
                                <div className="me-2" style={{ display: "inline-block" }}>
                                    <span>
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </span>
                                </div>
                                <div
                                    className=""
                                    style={{
                                        fontSize: "0.9em",
                                        fontWeight: "500",
                                        paddingTop: "1px",
                                        display: phoneSize ? "inline" : "inline-block",
                                    }}
                                >
                                    <span>NOT WHAT YOU WERE LOOKING FOR? CONTACT US FOR HELP</span>
                                </div>
                            </span>
                        </div>
                        {/* Scrollable divs search packages */}
                        <div style={{ height: "70vh", flexDirection: "column", overflow: "auto" }}>
                            <div style={{ display: "flex", flexDirection: "column", flexShrink: "0" }}>{packages.length === 0 ? searchLoading && <Oval color="#00BFFF" height={40} width={40} /> : packages.slice(offset, offset + (midSizeScreen ? 4 : 10)).map((packageRow, index) => <Package packageRow={packageRow} index={index} packageID={packageID} buttonColor={buttonColor} handleAddToReport={handleAddToReport} addToLoad={addToLoad} checkIcon={checkIcon} />)}</div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12" style={{ paddingLeft: midSizeScreen ? "" : "40px" }}>
                        <FormPatientName />
                        <div
                            id="sticky-container"
                            style={{
                                position: "-webkit-sticky",
                                position: "sticky",
                                top: "20px",
                            }}
                        >
                            <div
                                style={{
                                    height: "70vh",
                                    flexDirection: "column",
                                    overflow: "auto",
                                }}
                            >
                                <div
                                    className="mb-4"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        flexShrink: "0",
                                    }}
                                >
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {requestPackages.map((packageRow, index) => (
                                                        <Draggable key={"drag-" + index} draggableId={index.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                                                    {/* {console.log("requestPackage: ", packageRow)} */}
                                                                    <div key={index} className="card package-card-container" id={"requestPackage-" + packageRow.request_package.requestPackageID}>
                                                                        <div
                                                                            className="card-header package-card-header"
                                                                            style={{
                                                                                backgroundColor: selected === packageRow.request_package.requestPackageID ? "#D2F1FB" : "",
                                                                            }}
                                                                            onClick={(e) => {
                                                                                setSelected(packageRow.request_package.requestPackageID);
                                                                            }}
                                                                        >
                                                                            <span
                                                                                {...provided.dragHandleProps}
                                                                                className="dnd-handle"
                                                                                style={{
                                                                                    color: selected === packageRow.request_package.requestPackageID ? "#1db8ea" : "#bebed0",
                                                                                }}
                                                                                data-tip
                                                                                data-for="dragDrop-tooltip"
                                                                                data-event="mouseenter"
                                                                                data-event-off="mouseleave"
                                                                            >
                                                                                <FontAwesomeIcon icon={faEllipsisV} />
                                                                            </span>
                                                                            {!dragDropTooltip && (
                                                                                <ReactTooltip id="dragDrop-tooltip" effect="solid" multiline={true} delayHide={500} delayUpdate={500} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                                                                                    <span className="close-tooltip">
                                                                                        <FontAwesomeIcon
                                                                                            onClick={(e) => setDragDropTooltip(true)}
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                            icon={faTimes}
                                                                                            color="#B7B7CB"
                                                                                        />
                                                                                    </span>
                                                                                    <span>
                                                                                        Drag and drop packages
                                                                                        <br />
                                                                                        to reorder!
                                                                                    </span>
                                                                                </ReactTooltip>
                                                                            )}
                                                                            <span
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    float: "right",
                                                                                }}
                                                                                onClick={(e) => deleteReportPackage(packageRow.request_package.requestPackageID)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faTimes} style={{ color: "#bb0046" }} />
                                                                            </span>
                                                                            <div className="row" style={{ marginTop: "12px" }}>
                                                                                <div className="col-12" style={{ padding: "0px 5px" }}>
                                                                                    <a
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            marginLeft: "10px",
                                                                                            marginRight: "10px",
                                                                                            fontSize: "12px",
                                                                                            textDecoration: "none",
                                                                                            color: "black",
                                                                                        }}
                                                                                        ref={(ref) => {
                                                                                            billsTooltipRef.current = ref;
                                                                                        }}
                                                                                        data-bs-toggle="collapse"
                                                                                        href={"#bills-" + packageRow.request_package.requestPackageID}
                                                                                        aria-expanded="false"
                                                                                        aria-controls={"bills-" + packageRow.request_package.requestPackageID}
                                                                                        data-tip
                                                                                        data-for="collapse-icon-tooltip"
                                                                                        data-event="mouseenter"
                                                                                        data-event-off="mouseleave"
                                                                                        onClick={(e) => {
                                                                                            setBillsTooltip(true);
                                                                                            billsExpand === packageRow.request_package.requestPackageID ? setBillsExpand() : setBillsExpand(packageRow.request_package.requestPackageID);
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faChevronDown} className={"chevron " + (billsExpand === packageRow.request_package.requestPackageID && selected === packageRow.request_package.requestPackageID ? "rotate" : "")} />
                                                                                    </a>
                                                                                    <b
                                                                                        style={{
                                                                                            fontSize: "18px",
                                                                                            fontWeight: 500,
                                                                                            color: selected === packageRow.request_package.requestPackageID ? "#000046" : "#323232",
                                                                                        }}
                                                                                    >
                                                                                        {packageRow.request_package.title}
                                                                                    </b>
                                                                                    {!billsTooltip && (
                                                                                        <ReactTooltip id="collapse-icon-tooltip" effect="solid" multiline={true} delayHide={500} delayUpdate={500} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                                                                                            <span className="close-tooltip">
                                                                                                <FontAwesomeIcon
                                                                                                    onClick={(e) => closePackageTooltip()}
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    icon={faTimes}
                                                                                                    color="#B7B7CB"
                                                                                                />
                                                                                            </span>
                                                                                            <span>
                                                                                                Expand the procedure dropdown to
                                                                                                <br />
                                                                                                see and adjust details
                                                                                            </span>
                                                                                        </ReactTooltip>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ marginLeft: 20, marginTop: 10 }}>
                                                                                <div style={{ marginTop: 10 }}>
                                                                                    {/* Occurrence Text */}
                                                                                    <a
                                                                                        className="link-primary"
                                                                                        style={{
                                                                                            cursor: "pointer",
                                                                                            textDecoration: "none",
                                                                                            color: "#1db8ea",
                                                                                        }}
                                                                                        data-bs-toggle="collapse"
                                                                                        href={"#frequencyForm-" + packageRow.request_package.requestPackageID}
                                                                                        aria-expanded="false"
                                                                                        aria-controls={"frequencyForm-" + packageRow.request_package.requestPackageID}
                                                                                        data-tip
                                                                                        data-for="occurrence-tooltip"
                                                                                        data-event="mouseenter"
                                                                                        data-event-off="mouseleave"
                                                                                        onClick={(e) => {
                                                                                            setOccurrenceOpen(true);
                                                                                            occurrenceExpand === packageRow.request_package.requestPackageID ? setOccurrenceExpand() : setOccurrenceExpand(packageRow.request_package.requestPackageID);
                                                                                        }}
                                                                                    >
                                                                                        {packageRow.request_package.multiplier + " "}
                                                                                        {packageRow.request_package.multiplier > 1 || packageRow.request_package.multiplier === 0 ? "occurrences" : "occurrence"}
                                                                                        {packageRow.request_package.multiplierText ? " (" + packageRow.request_package.multiplierText + ")" : ""}
                                                                                    </a>
                                                                                    {!occurrenceOpen && (
                                                                                        <ReactTooltip id="occurrence-tooltip" effect="solid" multiline={true} delayHide={500} delayUpdate={500} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                                                                                            <span className="close-tooltip ">
                                                                                                <FontAwesomeIcon
                                                                                                    onClick={(e) => closeOccurrenceTooltip()}
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    icon={faTimes}
                                                                                                    color="#B7B7CB"
                                                                                                />
                                                                                            </span>
                                                                                            <span>
                                                                                                Click to edit the frequency and
                                                                                                <br />
                                                                                                duration of treatment
                                                                                            </span>
                                                                                        </ReactTooltip>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={"card package-card-collapse-container occurrence-card " + (occurrenceExpand === packageRow.request_package.requestPackageID && selected === packageRow.request_package.requestPackageID ? "expand" : "")}
                                                                        style={{
                                                                            backgroundColor: selected === packageRow.request_package.requestPackageID ? "#ecf9fd" : "",
                                                                        }}
                                                                    >
                                                                        <div className="card-body">
                                                                            {/* Ocurrances Form */}
                                                                            <div
                                                                                className="row g-0 mt-2"
                                                                                style={{
                                                                                    display: "block",
                                                                                }}
                                                                            >
                                                                                <div className="col-12" style={{ paddingLeft: "15px" }}>
                                                                                    <FormFrequencyDuration requestPackageID={packageRow.request_package.requestPackageID} setOccurrenceExpand={setOccurrenceExpand} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"card package-card-collapse-container bills-card " + (billsExpand === packageRow.request_package.requestPackageID && selected === packageRow.request_package.requestPackageID ? "expand" : "")}>
                                                                        <div
                                                                            className="card-body"
                                                                            style={{
                                                                                padding: "0px",
                                                                            }}
                                                                        >
                                                                            {/* Description && bills? */}
                                                                            <div
                                                                                className="row g-0"
                                                                                style={{
                                                                                    backgroundColor: selected === packageRow.request_package.requestPackageID ? "#D2F1FB" : "",
                                                                                    padding: "16px 10px 32px 16px",
                                                                                }}
                                                                            >
                                                                                <div className="col-12" style={{ paddingLeft: "15px" }}>
                                                                                    {packageRow.request_package.description}
                                                                                </div>
                                                                            </div>
                                                                            {packageRow.request_bill.length > 0 && (
                                                                                <div
                                                                                    className="row g-0 text-muted"
                                                                                    style={{
                                                                                        backgroundColor: selected === packageRow.request_package.requestPackageID ? "#D2F1FB" : "",
                                                                                        padding: "0px 10px 0px 31px",
                                                                                    }}
                                                                                >
                                                                                    <div className="col-10" style={{ paddingRight: "24px" }}>
                                                                                        Bill Title
                                                                                    </div>

                                                                                    <div className="col-2" style={{ paddingLeft: "0px" }}>
                                                                                        Visits
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {packageRow.request_bill?.map((bill, billIndex) => (
                                                                                <div
                                                                                    className={"row g-0 " + (selected === packageRow.request_package.requestPackageID ? "row-striped-bills" : "")}
                                                                                    style={{
                                                                                        padding: "12px 10px 12px 31px",
                                                                                    }}
                                                                                    key={"bill-" + billIndex}
                                                                                >
                                                                                    {/* {console.log("package: ", packageRow)} */}
                                                                                    <div
                                                                                        className="col-10"
                                                                                        style={{
                                                                                            paddingRight: "24px",
                                                                                            textDecoration: bill.isIncluded ? "" : "line-through",
                                                                                        }}
                                                                                    >
                                                                                        {bill.title}
                                                                                    </div>

                                                                                    <div
                                                                                        className="col-1 text-center"
                                                                                        style={{
                                                                                            paddingLeft: "0px",
                                                                                            textDecoration: bill.isIncluded ? "" : "line-through",
                                                                                        }}
                                                                                    >
                                                                                        {bill.quantity}
                                                                                    </div>
                                                                                    <div className="col-1">
                                                                                        {bill.isIncluded ? (
                                                                                            <span
                                                                                                className="float-end"
                                                                                                style={{
                                                                                                    color: "#bb0046",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    setIncludeTootlip(true);
                                                                                                    removeBill(packageRow.request_package.requestPackageID, bill.requestBillID);
                                                                                                }}
                                                                                                data-tip
                                                                                                data-for="include-tooltip"
                                                                                                data-event="mouseenter"
                                                                                                data-event-off="mouseleave"
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faTimes} />
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span
                                                                                                className="float-end"
                                                                                                style={{
                                                                                                    color: "green",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    setIncludeTootlip(true);
                                                                                                    includeBill(packageRow.request_package.requestPackageID, bill.requestBillID);
                                                                                                }}
                                                                                                data-tip
                                                                                                data-for="include-tooltip"
                                                                                                data-event="mouseenter"
                                                                                                data-event-off="mouseleave"
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faPlus} color="#1db8ea" />
                                                                                            </span>
                                                                                        )}
                                                                                        {!includeTooltip && (
                                                                                            <ReactTooltip id="include-tooltip" place="left" effect="solid" multiline={true} delayHide={500} delayUpdate={500} textColor="#000000" backgroundColor="#e8ff8b" borderColor="#e8ff8b" arrowColor="#e8ff8b">
                                                                                                <span className="close-tooltip">
                                                                                                    <FontAwesomeIcon
                                                                                                        onClick={(e) => closeBillTooltip()}
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                        }}
                                                                                                        icon={faTimes}
                                                                                                        color="#B7B7CB"
                                                                                                    />
                                                                                                </span>
                                                                                                <span>
                                                                                                    You can delete package details from
                                                                                                    <br />
                                                                                                    here. If you need to re-ad a detail
                                                                                                    <br />
                                                                                                    you can also do that from here
                                                                                                </span>
                                                                                            </ReactTooltip>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    {packageLoading && <Oval color="#00BFFF" height={40} width={40} />}
                                </div>
                                {session.email || session.isSignedEula ? (
                                    <Link to={requestPackages.length === 0 || requestPackages.some((row) => row.request_package.quantity === 0) ? "#" : "/builder/standard_mcp"}>
                                        <button className="btn access-primary-button" disabled={requestPackages.length === 0 || requestPackages.some((row) => row.request_package.quantity === 0) || !session.isSignedEula}>
                                            CONTINUE
                                        </button>
                                    </Link>
                                ) : (
                                    <>
                                        {added ? (
                                            <>
                                                <h3 className="text-uppercase mb-4">Want to use the full builder?</h3>
                                                <div>
                                                    <button
                                                        className="btn access-primary-button"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#eula"
                                                        style={{
                                                            marginRight: "24px",
                                                        }}
                                                    >
                                                        SIGN OUR USER AGREEMENT
                                                    </button>
                                                    <Link to={requestPackages.length === 0 && (requestPackages.some((row) => row.request_package.quantity === 0) || !session.isSignedEula) ? "#" : "/builder/standard_mcp"}>
                                                        <button className={"btn access-primary-blue " + (phoneSize ? "mt-3" : "")} disabled={!session.isSignedEula}>
                                                            CONTINUE
                                                        </button>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="eula" tabIndex="-1" aria-hidden="true" data-bs-backdrop={"static"} data-bs-keyboard={"false"}>
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "600px" }}>
                    <div className="modal-content modal-eula-sign">
                        <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faTimes}
                            className="card-close"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setAddToLoad(false);
                            }}
                        />
                        <div
                            className="modal-header p-0 m-0 w-100 justify-content-center text-center"
                            style={{
                                borderBottom: "none",
                                color: "#000064",
                                font: "700 22pt Montserrat",
                            }}
                        >
                            Sign our User Agreement to continue
                        </div>
                        <div className="modal-body" style={{ paddingTop: "2rem" }}>
                            <FormEula packageID={passPackageID} setAddToLoad={setAddToLoad} />
                        </div>
                    </div>
                </div>
            </div>
            <SOSModal standard={true} pro={false} expert={false} />
        </>
    );
};
